<template lang="pug">
v-dialog(v-model="showDialog" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y="" max-width="700px" max-height="850px")
  template(v-slot:activator="{ on, attrs }")
    v-text-field(v-model="icon" dark background-color="accent" :prepend-inner-icon="selectedIcon" label="Avatar (Opcional)" v-bind="attrs" v-on="on" readonly="" filled)
  v-card
    v-app-bar(color="white" scroll-target="#scrolling-techniques-7" elevation="0")
      v-text-field(v-model="searchIcon" placeholder="Search icon" prepend-icon="mdi-magnify" type="text" onautocomplete="off" dense="")
    v-sheet#scrolling-techniques-7.overflow-y-auto(max-height="600")
      v-col(cols="12")
        v-btn.mr-2.mb-2(v-for="(item, i) in allIcons" :key="i" @click="saveIcon(item)" color="white" fab="" small="" depressed="")
          v-icon(color="grey darken-3")  {{ item }}
</template>

<script>
import icons from './icons.json'

export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showDialog: false,
      icon: this.value || '',
      searchIcon: '',
      selectedIcon: this.value || 'mdi-check-outline',
      allIcons: icons
    }
  },
  methods: {
    saveIcon (icon) {
      this.icon = icon
      this.selectedIcon = icon
      this.showDialog = false
    }
  },
  watch: {
    searchIcon () {
      let filteredIcons = []
      if (this.searchIcon.length === 0) {
        filteredIcons = this.allIcons
      } else {
        filteredIcons = this.allIcons.filter(i => i.includes(this.searchIcon))
      }
      this.allIcons = filteredIcons
    },
    icon () {
      this.$emit('input', this.icon)
    }
  }
}
</script>
