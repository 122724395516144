<template lang="pug">
v-dialog(v-model="dialog" max-width="600px")
  v-card(color="#003399")
    v-card-title.white--text Probar bot {{ bot.name }}
    v-card-text
      h3.white--text.mb-2 Parametros de entrada
      v-form(ref="form" @submit.prevent="onSubmit")
        v-text-field(v-for="param in bot.params" background-color="accent" filled :label="param.field" v-model="form[param.field]" dark :placeholder="param.description")
        v-btn(color="tertiary" type="submit" block depressed large) Probar
  
  v-dialog(v-model="dialogResult" max-width="500px")
    v-card(color="#003399")
      v-card-text
        .d-flex.justify-center.align-center(style="height: 150px;")
          v-icon(size="80" color="tertiary") mdi-check-circle
        h1.text-center.white--text.mb-10(style="line-height: 2rem;") La tarea se añadio a la cola de ejecución, puedes ver el resultado en la sección de Reportes
        div.white--text.mb-8
          v-row
            v-col(cols=6)
              .mb-3 Fecha de creación
              div {{ result?.created_at | moment('DD/MM/YYYY HH:mm:ss') }}
            v-col(cols=6)
              .mb-3 ID de trabajo
              div {{ '#' + result?.id }}
        v-btn(color="tertiary" outlined @click="dialog = false" block depressed large) Cerrar
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
      dialogResult: false,
      result: null,
      bot: {},
      form: {},
      logs: []
    }
  },
  methods: {
    onSubmit () {
      this.$store.dispatch('bots/testBot', { id: this.bot.id, data: this.form })
        .then(data => {
          this.result = data
          this.dialogResult = true
        })
        .catch((err) => {
          this.errors = err.response.data
        })
    },
    open (bot) {
      this.errors = {}
      this.bot = bot
      this.dialog = true
      this.form = {}
    }
  }
}
</script>
