<template lang="pug">
v-pagination(
  v-model="tableOptions.page"
  :length="pageCount"
  :total-visible="7"
  circle
  color="primary"
  :disabled="pageCount <= 1"
)
</template>

<script>
import { mapFields } from 'vuex-map-fields'

const RESULTS_PER_PAGE = 10

export default {
  props: {},
  computed: {
    ...mapFields('projectListView', [
      'queryParams',
      'itemsLength',
      'tableOptions',
    ]),

    pageCount() {
      return Math.ceil(this.itemsLength / RESULTS_PER_PAGE)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-pagination__item:not(.v-pagination__item--active),
  .v-pagination__navigation {
    box-shadow: none;
  }
}
</style>
