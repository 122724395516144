import axios from '@/plugins/axios'

export default {
  listApps (_, { params } = {}) {
    return axios.get('/projects/apps/', { params })
      .then(response => response.data)
  },
  getApp (_, { id }) {
    return axios.get(`/projects/apps/${id}/`)
      .then(response => response.data)
  },
  createApp (_, { data }) {
    return axios.post('/projects/apps/', data)
      .then(response => response.data)
  },
  updateApp (_, { id, data }) {
    return axios.put(`/projects/apps/${id}/`, data)
      .then(response => response.data)
  },
  deleteApp (_, { id }) {
    return axios.delete(`/projects/apps/${id}/`)
      .then(response => response.data)
  }
}
