import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import AutoField from './components/common/AutoField.vue'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import { isAuthenticated } from './utils/authUtils'
import { can, permissions } from './utils/permissionsUtils'

moment.locale('es')
Vue.use(VueMoment, {
  moment,
})

Vue.config.productionTip = false

Vue.prototype.$auth = {
  permissions,
  isAuthenticated,
  userCan: (permission) => {
    const user = store.getters['auth/user']
    for (const role of user.groups) {
      if (can(permission, role)) {
        return true
      }
    }
    return false
  },
  checkRoles: (roles) => {
    const user = store.getters['auth/user']

    if (!user) {
      return false
    }
    if (!Array.isArray(roles)) {
      roles = [roles]
    }
    const userRoles = user.groups
    return roles.some((role) => userRoles.includes(role))
  }
}

Vue.component('AutoField', AutoField)

const init = async () => {

  if (isAuthenticated()) {
    await store.dispatch('auth/fetchUser')
  }

  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app')

}

init()