import axios from '@/plugins/axios'

export default {
  listTasks (_, { params } = {}) {
    return axios.get('/projects/tasks/', { params })
      .then(response => response.data)
  },
  listTreeTasks ({ commit }, { params } = {}) {
    return axios.get('/projects/tasks/list_tree/', { params })
      .then(response => {
        commit('setTaskGraph', response.data)
        return response.data
      })
  },
  getTask (_, { id }) {
    return axios.get(`/projects/tasks/${id}/`)
      .then(response => response.data)
  },
  createTask (_, { data }) {
    return axios.post('/projects/tasks/', data)
      .then(response => response.data)
  },
  updateTask (_, { id, data }) {
    return axios.put(`/projects/tasks/${id}/`, data)
      .then(response => response.data)
  },
  deleteTask (_, { id }) {
    return axios.delete(`/projects/tasks/${id}/`)
      .then(response => response.data)
  },
  runTask (_, { id, data }) {
    return axios.post(`/projects/tasks/${id}/run/`, data)
      .then(response => response.data)
  },
  setIsActiveTask (_, { id, data }) {
    return axios.put(`/projects/tasks/${id}/set_is_active/`, data)
      .then(response => response.data)
  }
}