<template lang="pug">
v-dialog(v-model="isOpen" width="1200")
  v-card(v-if="!loading" color="secondary")
    v-card-title.white--text Logs de instalación
    v-card-text
      v-simple-table.mb-4
        template(#default)
          thead
            tr
              th(v-for="header in headers" :key="header.text") {{ header.text }}
          tbody
            tr(v-for="log in logs" :key="log.id" :class="log.type === 'ERROR' ? 'error-row' : ''")
              td(style="min-width: 200px") {{ log.created_at | moment('DD/MM/YYYY HH:mm:ss') }}
              td {{ log.log }}
              td {{ log.type }}
      .text-right
        v-btn(color="tertiary" @click="isOpen = false") Cerrar
  v-card(v-else color="secondary")
    v-card-text
      .text-center.my-4
        v-progress-circular(
          indeterminate
          color="tertiary"
          :size="70"
        )
</template>

<script>
export default {
  data() {
    return {
      logs: [],
      isOpen: false,
      loading: false,
      headers: [
        { text: 'Fecha', value: 'created_at' },
        { text: 'Mensaje', value: 'log' },
        { text: 'Tipo', value: 'type' }
      ]
    }
  },
  methods: {
    async open(botId) {
      this.loading = true
      this.isOpen = true
      this.logs = await this.$store.dispatch('bots/getInstallationLogs', { id: botId })
        .catch(err => {
          if (err) {
            this.$toast.error('Error al obtener los logs')
            this.isOpen = false
          }
          return null
        })
      this.loading = false
    },
    closeModal() {
      this.$emit('close')
    }
  },

}
</script>

<style lang="scss">
.error-row {
  background-color: #f443364f !important;
}
</style>