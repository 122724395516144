<template lang="pug">
v-card(width="282px" height="454px" color="#8E9DE8")
  .content.flex-col.gap-2.align-center.h-100
    v-img(src="@/assets/images/project-list-banner.svg" height="250px")
    span.start-here
      | Empieza tu nuevo
      br
      | proyecto aquí

    .buttons.flex-col
      v-btn(
        v-if="$auth.userCan($auth.permissions.CAN_CREATE_PROJECT)"
        large depressed color="primary"
        @click="$emit('createModal')"
      ) Crear nuevo proyecto
      v-btn(
        large depressed outlined color="white"
      ) Guía rápida
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.content {
  padding: 16px;
}

.start-here {
  text-align: center;
  color: #fff;
  line-height: normal;
  font-weight: bold;
  font-size: 16px;
}

.buttons {
  gap: 4px;
  width: 100%;

  .v-btn {
    font-weight: 400;
  }
}
</style>
