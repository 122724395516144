<template lang="pug">
components(:is="layout" :key="layout")
  router-view
</template>

<script>
import AuthLayout from './layouts/AuthLayout.vue'
import DefaultLayout from './layouts/DefaultLayout.vue'

export default {
  name: 'App',
  components: {
    AuthLayout,
    DefaultLayout
  },
  computed: {
    layout () {
      return this.$route.meta.layout || 'DefaultLayout'
    }
  },
}
</script>

<style lang="scss">
@import '@/styles/global.scss';
@import '@/styles/global-vuetify-overrides.scss';
</style>
