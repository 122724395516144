import axios from '@/plugins/axios'

export default {
  listBots (_, { params } = {}) {
    return axios.get('/projects/bots/', { params })
      .then(response => response.data)
  },
  getBot (_, { id }) {
    return axios.get(`/projects/bots/${id}/`)
      .then(response => response.data)
  },
  createBot (_, { data }) {
    const formData = new FormData()
    Object.keys(data).forEach(key => formData.append(key, data[key]))
    return axios.post('/projects/bots/', data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(response => response.data)
  },
  updateBot (_, { id, data }) {
    const formData = new FormData()
    Object.keys(data).forEach(key => formData.append(key, data[key]))
    return axios.put(`/projects/bots/${id}/`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(response => response.data)
  },
  deleteBot (_, { id }) {
    return axios.delete(`/projects/bots/${id}/`)
      .then(response => response.data)
  },
  testBot (_, { id, data }) {
    return axios.post(`/projects/bots/${id}/test/`, data)
      .then(response => response.data)
  },
  stopTaskExecutionByBot (_, { id }) {
    return axios.post(`/projects/bots/${id}/stop/`)
      .then(response => response.data)
  },
  getInstallationLogs (_, { id }) {
    return axios.get(`/projects/bots/${id}/installation_logs/`)
      .then(response => response.data)
  }
}
