<template lang="pug">
div
  v-dialog(
    v-if="isOpen"
    :value="isOpen"
    @input="$emit('input', $event)"
    max-width="600"
  )
    v-card(color="#003399" dark)
      v-card-title Eliminar Proyecto [{{ project.name }}]
      v-card-text
        p.text ¿Confirma que desea eliminar el proyecto?
        .flex-row.justify-end.buttons
          v-btn(
            text depressed large color="tertiary"
            :loading="loading"
            @click="isOpen = false"
          ) CANCELAR
          v-btn(
            depressed large color="red lighten-1"
            :loading="loading"
            @click="confirmDelete"
          ) ELIMINAR
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      isOpen: false,
      project: null,
      loading: false,
    }
  },

  methods: {
    ...mapActions('projects', ['deleteProject']),

    open(porject) {
      this.project = porject
      this.isOpen = true
    },

    async confirmDelete() {
      this.loading = true
      try {
        await this.deleteProject({ id: this.project.id })
        this.$toast.warning('Proyecto eliminado')
        this.$emit('input', false)
        this.$emit('changed')
        this.isOpen = false
        //
      } catch (error) {
        this.$toast.error('Error al eliminar el proyecto')
        console.log(error)
        //
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  justify-content: end;
  gap: 10px;

  & > .v-btn {
    min-width: 100px;
  }
}

.text {
  font-size: 16px;
}
</style>
