<template lang="pug">
.flex-col
  .stats-title {{ title }}
  .flex-row.align-center
    span.stats-value {{ value }}
    span.stats-label {{ label }}
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.stats-title {
  font-size: 14px;
  font-weight: 700;
  // margin-bottom: 0.5rem;
}

.stats-value {
  font-size: 32px;
  font-weight: 700;
  margin-right: 0.5rem;
}

.stats-label {
  font-size: 12px;
  font-weight: 500;
}
</style>
