<template lang="pug">
v-dialog(:value="value" @input="$emit('input', $event)" max-width="525px")
  v-card(width="525px" height="400px" color="#003399").card
    v-card-title.mb-3 {{ editingMode ? 'Editar proyecto' : 'Proyecto nuevo' }}
    v-card-text.flex-col.gap-2.flex-grow-1
      v-form(v-model="formValid" ref="form" lazy-validation @submit.prevent.stop="submit" :loading="loading").flex-grow-1

        v-text-field(
          v-model="form.name"
          label="Nombre del proyecto"
          :rules="[v => !!v || 'El nombre es requerido']"
          dense dark filled background-color="accent"
        )

        v-textarea(
          v-model="form.description"
          label="Descripción"
          :rules="[v => !!v || 'La descripción es requerida']"
          dense dark filled background-color="accent"
          rows="3" no-resize
        )

    v-card-actions.buttons
      v-btn(
        text depressed large color="tertiary"
        @click="$emit('input', false)"
      ) CANCELAR
      v-btn(
        depressed large color="primary"
        @click="submit"
      ) {{ editingMode ? 'GUARDAR' : 'CREAR' }}
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        name: '',
        description: '',
      },
      loading: false,
      formValid: true,
      editingMode: false,
    }
  },
  computed: {
    projectId() {
      return this.$route.query.editProjectId
    },
  },
  methods: {
    ...mapActions('projects', ['createProject', 'updateProject', 'getProject']),
    async submit() {
      if (!this.$refs.form.validate()) return
      this.loading = true

      const { name, description } = this.form
      const data = { name, description }

      try {
        const action = this.editingMode
          ? this.updateProject({ id: this.projectId, data})
          : this.createProject({ data })
        await action
        if (this.editingMode) {
          this.$toast.success('Proyecto actualizado correctamente')
        } else {
          this.$toast.success('Proyecto creado correctamente')
        }

        this.$emit('changed', false)
        this.$emit('input', false)
        //
      } catch (error) {
        if (this.editingMode) {
          if (error.response.status === 403) {
            this.$toast.error('No tienes permisos para editar el proyecto')
          } else {
            this.$toast.error('Error al actualizar el proyecto')
          }
        } else {
          this.$toast.error('Error al crear el proyecto')
        }
        //
      } finally {
        this.loading = false
      }
    },
  },
  watch: {
    /** Reset form when closed */
    value(val) {
      if (!val) {
        this.$refs.form.reset()
        this.editingMode = false
      }
    },

    '$route.query.editProjectId': {
      async handler(val) {
        if (!val) return
        if (!this.value) {
          this.$emit('input', true)
        }
        this.loading = true

        try {
          const data = await this.getProject({ id: val })
          this.form = data
          this.editingMode = true
          //
        } catch (error) {
          this.$toast.error('Error al cargar el proyecto')
          //
        } finally {
          this.loading = false
        }
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: #fff;
}

.buttons {
  display: flex;
  justify-content: end;
  gap: 10px;

  & > .v-btn {
    min-width: 100px;
  }
}
</style>
