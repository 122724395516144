<template lang="pug">
v-autocomplete(
  :value="value"
  @input="$emit('input', $event)"
  :items="items"
  :search-input.sync="search"
  :loading="loading"
  :clearable="options.clearable == null ? true : options.clearable"
  hide-selected
  no-data-text="No se encontraron datos"
  @focus="search = ''"
  v-bind="options"
)
  slot(v-for="name in $slots" :name="name" :slot="name")
  template(v-for="(_,name) in $scopedSlots" :slot="name" slot-scope="slotProps")
    slot(:name="name" v-bind="slotProps")

</template>

<script>
import { debounce } from 'lodash'

export default {
  props: {
    value: {
      type: null,
      default: null,
    },
    fetch: {
      type: Function,
      required: true,
    },
    extraParams: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      search: null,
      loading: false,
      items: [],
    }
  },

  computed: {
    params() {
      return {
        search: this.search,
        ...this.extraParams,
      }
    },
  },

  watch: {
    search: {
      handler(val) {
        this.onChange(val)
      },
    },

    value: {
      handler(val, oldVal) {
        if (oldVal == null && val != null) {
          this.search = String(val.id)
        }
      },
    },
  },

  created() {
    if (this.value) {
      if (Array.isArray(this.value)) {
        this.items = this.value
      } else {
        this.items = [this.value]
      }
    }

    this.onChange = debounce(async function (search) {
      this.loading = true
      try {
        this.items = await this.fetch({ params: this.params })
      } catch (e) {
        this.$emit('error', e)
      } finally {
        this.loading = false
      }
    }, 200)
  },

  methods: {
    onChange() {},
  },
}
</script>

<style lang="scss" scoped></style>
