import actions from './actions'
import mutations from './mutations'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: mutations,
  actions: actions,
}
