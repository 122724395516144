import axios from '@/plugins/axios'

export default {
  listProcesses (_, { params } = {}) {
    return axios.get('/projects/processes/', { params })
      .then(response => response.data)
  },
  getProcess (_, { id }) {
    return axios.get(`/projects/processes/${id}/`)
      .then(response => response.data)
  },
  createProcess (_, { data }) {
    return axios.post('/projects/processes/', data)
      .then(response => response.data)
  },
  updateProcess (_, { id, data }) {
    return axios.put(`/projects/processes/${id}/`, data)
      .then(response => response.data)
  },
  deleteProcess (_, { id }) {
    return axios.delete(`/projects/processes/${id}/`)
      .then(response => response.data)
  }
}
