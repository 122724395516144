import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import { VSnackbar, VBtn, VIcon } from 'vuetify/lib'
import VuetifyToast from 'vuetify-toast-snackbar-ng'
import { vuetifyToastOptions } from './toast'

Vue.use(Vuetify, { components: { VSnackbar, VBtn, VIcon } })
Vue.use(VuetifyToast, vuetifyToastOptions)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#075EE7',
        secondary: '#003399',
        tertiary: '#80D7FB',
        accent: '#8E9DE8',
        error: '#80D7FB',
      },
    },
  },
})
