<template lang="pug">
v-dialog(v-model="dialog" max-width="600px")
  v-card(v-if="!loading" color="#003399")
    v-card-title.white--text Creación nuevo bot
    v-card-text
      BaseBotForm(@submit="onSubmit" :key="dialog" :errors="errors")
  v-card(v-else color="#003399")
    v-card-title.white--text Instalando el bot
    v-card-text
      .text-center
        v-progress-circular(
          indeterminate
          color="tertiary"
          :size="70"
        )
</template>

<script>
import BaseBotForm from './BaseBotForm.vue'

export default {
  name: 'CreateBotForm',
  components: {
    BaseBotForm
  },
  data () {
    return {
      dialog: false,
      loading: false,
      errors: {}
    }
  },
  methods: {
    onSubmit (data) {
      this.loading = true
      this.$store.dispatch('bots/createBot', { data })
        .then(() => {
          this.$emit('saved')
          this.dialog = false
        })
        .catch((err) => {
          this.errors = err.response.data
          this.loading = false
        })
    },
    open () {
      this.errors = {}
      this.loading = false
      this.dialog = true
    }
  }
}
</script>
