import { getField, updateField } from 'vuex-map-fields'

const actions = {}

export default {
  namespaced: true,
  state: {
    project: {},
  },
  getters: { getField },
  mutations: { updateField },
  actions: actions,
}
