import axios from '@/plugins/axios'
import router from '../../router'

export default {
  login ({ commit, dispatch }, { data }) {
    console.log(data)
    return axios.post('/auth/login/', data)
      .then(async(response) => {
        commit('setToken', response.data.access_token)
        await dispatch('fetchUser')
        return response
      })
  },
  logout ({ commit }) {
    return axios.post('/auth/logout/')
      .then((response) => {
        return response
      })
      .finally(() => {
        commit('setToken', null)
        commit('setUser', null)
        router.push('/login')
      })
  },

  async fetchUser ({ commit }) {
    const user = (await axios.get(`/projects/users/me/`)).data
    commit('setUser', user)
  },

  resetPassword (_, { data }) {
    return axios.post('/auth/password/reset/', data)
      .then(response => response.data)
  }
}
