import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'
import ListBotsView from '../views/ListBotsView.vue'
import ProjectListView from '@/views/ProjectListView.vue'
import ProjectDetailsView from '@/views/ProjectDetailsView.vue'
import ResetPasswordView from '../views/ResetPasswordView.vue'
import { isAuthenticated } from '../utils/authUtils'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'OverviewHome' },
    name: 'Home',
  },
  // auth
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    meta: {
      layout: 'AuthLayout',
      public: true,
      unauthenticatedOnly: true,
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPasswordView,
    meta: {
      layout: 'AuthLayout',
      public: true,
      unauthenticatedOnly: true,
    },
  },
  {
    path: '/overview',
    name: 'OverviewView',
    component: () => import('@/views/OverviewView.vue'),
    children: [
      {
        path: 'home',
        name: 'OverviewHome',
        component: () => import('@/components/overview/TabHome.vue'),
      },
      {
        path: 'procesos',
        name: 'OverviewProcess',
        component: () => import('@/components/overview/TabProcess.vue'),
      },
      {
        path: 'procesos/:id',
        name: 'OverviewProcessDetail',
        component: () => import('@/components/overview/ProcessDetail.vue'),
      },
      {
        path: 'bots',
        name: 'OverviewBots',
        component: () => import('@/components/overview/TabBots.vue'),
      },

    ],
  },
  {
    path: '/projects',
    name: 'ProjectListView',
    component: ProjectListView,
  },
  {
    path: '/projects/:id',
    name: 'ProjectDetailsView',
    component: ProjectDetailsView,
    redirect: { name: 'ProjectDetailsApplications' },
    children: [
      {
        path: 'details',
        name: 'ProjectDetailsDetails',
        component: () => import('@/components/project/details/TabDetails.vue'),
      },
      {
        path: 'users',
        name: 'ProjectDetailsUsers',
        component: () => import('@/components/project/details/TabUsers.vue'),
      },
      {
        path: 'applications',
        name: 'ProjectDetailsApplications',
        component: () => import('@/components/project/details/TabApplications.vue'),
      },
      {
        path: 'processes',
        name: 'ProjectDetailsProcesses',
        component: () => import('@/components/project/details/TabProcesses.vue'),
      },
    ],
  },
  {
    path: '/processes/:processId',
    name: 'ProcessDetailsView',
    component: () => import('@/views/ProcessDetailsView.vue'),
    redirect: { name: 'ProcessDetailsDetailsGraph' },
    children: [
      {
        path: 'table',
        name: 'ProcessDetailsDetailsTable',
        component: () => import('@/components/project/details/process/TabDetailsTable.vue'),
      },
      {
        path: 'graph',
        name: 'ProcessDetailsDetailsGraph',
        component: () => import('@/components/project/details/process/TabDetailsGraph.vue'),
      }
    ],
  },
  {
    path: '/processes/:processId/newTask',
    name: 'NewTaskView',
    alias: '/processes/:processId/editTask',
    component: () => import('@/views/NewTaskView.vue'),
  },
  {
    path: '/fabrica-bots',
    name: 'ListBotsView',
    component: ListBotsView,
  },
  {
    path: '/apps',
    name: 'AppsView',
    component: () => import('@/views/AppsView.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (!to.meta.public && !isAuthenticated()) {
    next('/login')
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.meta.unauthenticatedOnly && isAuthenticated()) {
    next('/')
  } else {
    next()
  }
})

export default router
