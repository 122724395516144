export const permissions = {
  // PROJECTS
  CAN_CREATE_PROJECT: 'CAN_CREATE_PROJECT',
  CAN_EDIT_PROJECT: 'CAN_EDIT_PROJECT',
  CAN_DELETE_PROJECT: 'CAN_DELETE_PROJECT',
  CAN_SEE_PROJECT: 'CAN_SEE_PROJECT',
  // TASKS
  CAN_CREATE_TASK: 'CAN_CREATE_TASK',
  CAN_EDIT_TASK: 'CAN_EDIT_TASK',
  CAN_DELETE_TASK: 'CAN_DELETE_TASK',
  // BOTS
  CAN_CREATE_BOT: 'CAN_CREATE_BOT',
  CAN_EDIT_BOT: 'CAN_EDIT_BOT',
  CAN_DELETE_BOT: 'CAN_DELETE_BOT',
  CAN_SEE_BOT: 'CAN_SEE_BOT',
}

export const userRoles = {
  ADMIN_ROLE: 'Administrador',
  PROJECT_ADMIN_ROLE: 'Administrador de proyectos',
  PROJECT_ENGINEER_ROLE: 'Ingeniero de proyectos',
  USER_APP_ROLE: 'Usuario app',
}

export const permissionsList = Object.values(permissions)

export const permissionsByRole = {
  [userRoles.ADMIN_ROLE]: [...permissionsList],
  [userRoles.PROJECT_ADMIN_ROLE]: [...permissionsList],
  [userRoles.PROJECT_ENGINEER_ROLE]: [
    // PROJECTS
    permissions.CAN_SEE_PROJECT,
    // TASKS
    permissions.CAN_CREATE_TASK,
    permissions.CAN_EDIT_TASK,
    permissions.CAN_DELETE_TASK,
    // BOTS
    permissions.CAN_CREATE_BOT,
    permissions.CAN_EDIT_BOT,
    permissions.CAN_DELETE_BOT,
    permissions.CAN_SEE_BOT,
  ],
  [userRoles.USER_APP_ROLE]: [],
}

export const can = (permission, role) => {
  return permissionsByRole[role].includes(permission)
}
