<template lang="pug">
.flex-row.gap-1.align-center
  StatsCard(
    title="User"
    :value="users"
    :label="users.length === 1 ? 'Usuario' : 'Usuarios'"
  )
  v-divider(:inset="true" vertical).mx-1
  StatsCard(
    title="Procesos"
    :value="processes"
    label="Ver detalles"
  )
  v-divider(:inset="true" vertical).mx-1
  StatsCard(
    title="APP"
    :value="applications"
    label="Ver detalles"
  )
  v-divider(:inset="true" vertical).mx-1
  StatsCard(
    title="Bot activos"
    :value="activeBots"
    label="Ver detalles"
  )
  v-divider(:inset="true" vertical).mx-1
</template>

<script>
import StatsCard from '@/components/project/details/StatsCard.vue'

export default {
  components: { StatsCard },
  props: {
    project: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    users() {
      return this.project.users?.length || 0
    },
    processes() {
      return this.project.processes?.length || 0
    },
    applications() {
      return this.project.apps?.length || 0
    },
    activeBots() {
      return this.project.processes?.reduce((accum, el) => accum + el.tasks.filter(t => t.is_active).length, 0) || 0
    },
  },
}
</script>

<style lang="scss" scoped></style>
