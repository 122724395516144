import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import bots from './bots'
import projects from './projects'
import processes from './processes'
import apps from './apps'
import tasks from './tasks'
import users from './users'
import projectListView from './projectListView'
import projectDetailsView from './projectDetailsView'
import processDetailsView from './processDetailsView'
import taskExecution from './taskExecution'
import botsMonitoring from './botsMonitoring'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    bots,
    projects,
    processes,
    apps,
    tasks,
    users,
    projectListView,
    projectDetailsView,
    processDetailsView,
    taskExecution,
    botsMonitoring
  }
})
