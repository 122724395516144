<template lang="pug">
.d-flex.flex-column.white--text.text-center.py-12(style="height: 100%; aling-items: center;")
  img.mx-auto(src="@/assets/images/logo-auth.png" alt="Logo" style="margin-top: 15%; margin-bottom: 12%;")
  v-form.mt-12.mx-auto(style="width: 307px;" @submit.prevent="sendRestPassEmail")
    .text-left.mb-4
      v-btn(icon)
        router-link(to="/login")
          v-icon.white--text mdi-arrow-left
    div(v-if="!ok")
      h2.text-white.mb-6.ml-6(style="max-width: 250px") Escribe tu mail y te enviaremos las instrucciones
      v-text-field(filled dark label="Email" v-model="form.email" :rules="v.email")
      .text-center.mb-4(v-if="error" style="color: #80D7FB; font-size: 12px;") *{{ error }}
      v-btn(@click="sendRestPassEmail" block variant="accent" large color="#80D7FB" style="color: #003399;") Enviar
      .text-center.mt-4
    div(v-else)
      h2.text-white.mb-6.ml-6(style="max-width: 250px") Email enviado
      router-link.text-decoration-none.white--text(to="/login") Volver al login
</template>

<script>
export default {
  name: 'LoginView',
  data: () => ({
    error: null,
    ok: false,
    form: {
      email: ''
    },
    v: {
      email: [
        v => !!v || 'El email es requerido'
      ]
    }
  }),
  methods: {
    sendRestPassEmail () {
      this.$store.dispatch('auth/resetPassword', { data: this.form })
        .then(() => (this.ok = true))
        .catch(err => {
          this.error = err.response.data.email ? err.response.data.email[0] : 'Error al enviar el email'
        })
    }
  }
}
</script>
