<template lang="pug">
v-container.px-6.py-12(fluid)
  v-form(@submit.prevent="fetchBots")
    v-row(dense)
      v-col(md=8)
        v-text-field(v-model="search" filled label="Buscar" prepend-inner-icon="mdi-magnify")
      v-col(md="auto")
        v-btn(color="primary" depressed height="56" filled type="submit")
          v-icon mdi-magnify
  v-row(justify="space-between")
    v-col(cols="auto")
      h3(style="color: #003399;") Bots existentes
    v-col(cols="auto")
      v-btn(color="primary" depressed large @click="$refs.createBotForm.open()") Nuevo robot
  v-simple-table
    template(v-slot:default)
      thead
        tr
          th(v-for="label in tableLabels" :key="label" style="color: #8E9DE8; border: 0;") {{ label }}
      tbody
        tr.my-2(v-for="bot in bots" :key="bot.id")
          td.my-2.d-flex(style="color: #8E9DE8; border: 0; max-width: 300px; ")
            v-sheet.mr-4.pa-1(color="accent" height="45" width="45" rounded)
              v-icon(color="white"  large) {{ bot.avatar || 'mdi-emoticon-happy-outline'}}
            div(style="align-self: center; font-weight: 700; word-break: break-all;") {{ bot.name }}
          td.my-2(style="color: #8E9DE8; border: 0; width: 50px;")
            v-chip(style="width: 100px; justify-content: center; color: #50341F;" :color="bot.state === 'Productivo' ? '#80D7FB' : '#E1E1E1'") {{ bot.state }}
          td.my-2(style="color: #8E9DE8; border: 0;")
            div {{ bot.params?.map(e => e.field).join(', ') }}
          td.my-2(style="color: #8E9DE8; border: 0; word-break: break-all;") {{ bot.description }}
          td.my-2(style="color: #8E9DE8; border: 0; min-width: 200px;")
            v-tooltip(top)
              template(v-slot:activator="{ on }")
                v-btn.mr-1(color="accent" icon text @click="$refs.updateBotForm.open(bot)" v-on="on")
                  v-icon mdi-pencil-outline
              span Editar bot
            v-tooltip(top)
              template(v-slot:activator="{ on }")
                v-btn.mr-1(color="accent" icon text @click="$refs.testBotForm.open(bot)" v-on="on")
                  v-icon mdi-bug-play-outline
              span Probar bot
            v-tooltip(top)
              template(v-slot:activator="{ on }")
                v-btn.mr-1(color="accent" icon text @click="deleteBot(bot)" v-on="on")
                  v-icon mdi-trash-can-outline
              span Eliminar bot
            v-tooltip(top)
              template(v-slot:activator="{ on }")
                v-btn(color="accent" icon text @click="$refs.installationLogsModal.open(bot.id)" v-on="on")
                  v-icon mdi-application-brackets-outline
              span Logs de la última instalación
  CreateBotForm(ref="createBotForm" @saved="fetchBots")
  UpdateBotForm(ref="updateBotForm" @saved="fetchBots")
  TestBotForm(ref="testBotForm" @saved="fetchBots")
  ConfirmModal(ref="confirmModal")
  InstallationLogsModal(ref="installationLogsModal")
</template>

<script>
import CreateBotForm from '../components/bots/forms/CreateBotForm.vue'
import UpdateBotForm from '../components/bots/forms/UpdateBotForm.vue'
import TestBotForm from '../components/bots/forms/TestBotForm.vue'
import ConfirmModal from '../components/common/ConfirmModal.vue'
import InstallationLogsModal from '../components/bots/InstallationLogsModal.vue'

export default {
  components: {
    CreateBotForm,
    UpdateBotForm,
    TestBotForm,
    ConfirmModal,
    InstallationLogsModal
  },
  data: () => ({
    search: '',
    tableLabels: [
      'Nombre',
      'Estado',
      'Parametros',
      'Descripción',
      'Acciones'
    ],
    bots: []
  }),
  methods: {
    async fetchBots () {
      this.bots = await this.$store.dispatch('bots/listBots', { params: { search: this.search } })
    },
    async deleteBot (bot) {
      if (bot.state === 'Productivo') {
        return this.$toast.error('No se puede eliminar un bot productivo')
      }
      const ok = await this.$refs.confirmModal.open('¿Está seguro que desea eliminar el bot?',
        'Esta acción no se puede deshacer, se eliminará el bot y todas sus tareas asociadas.')

      if (!ok) {
        return
      }
      await this.$store.dispatch('bots/deleteBot', { id: bot.id })
        .then(() => {
          this.$toast.success('Bot eliminado correctamente')
          this.fetchBots()
        })
        .catch(() => this.$toast.error('No se pudo eliminar el bot'))
    }
  },
  created () {
    this.fetchBots()
  }
}
</script>
