<template lang="pug">
.d-flex.flex-column.white--text.text-center.py-12(style="height: 100%; aling-items: center;")
  img.mx-auto(src="@/assets/images/logo-auth.png" alt="Logo" style="margin-top: 15%; margin-bottom: 12%;")
  v-form.mt-12.mx-auto(style="width: 307px;" ref="form" lazy-validation @submit.prevent="login")
    h2.text-white.mb-6.ml-6(style="max-width: 250px") Bienvenido a HealthTracker
    v-text-field(filled dark label="Nombre se usuario" v-model="form.username" :rules="v.username")
    v-text-field(filled dark label="Contraseña" v-model="form.password" type="password" :rules="v.password")
    .text-center.mb-4(v-if="error" style="color: #80D7FB; font-size: 12px;") *El nombre de usuario o constraseña incorrecto
    v-btn(type="submit"  block variant="accent" large color="#80D7FB" style="color: #003399;") Entrar
    .text-center.mt-4
      router-link.text-decoration-none.white--text(to="/reset-password") ¿Olvidaste tu contraseña?
</template>

<script>
export default {
  name: 'LoginView',
  data: () => ({
    error: false,
    form: {
      username: '',
      password: ''
    },
    v: {
      username: [
        v => !!v || 'El usuario es requerido'
      ],
      password: [
        v => !!v || 'La contraseña es requerida'
      ]
    }
  }),
  methods: {
    login () {
      this.$store.dispatch('auth/login', { data: { username: this.form.username, password: this.form.password } })
        .then(() => { this.$router.push({ name: 'Home' }) })
        .catch(() => (this.error = true))
    }
  }
}
</script>
