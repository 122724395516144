import axios from '@/plugins/axios'

export default {
  listProjects (_, { params } = {}) {
    return axios.get('/projects/projects/', { params })
      .then(response => response.data)
  },
  getProject (_, { id }) {
    return axios.get(`/projects/projects/${id}/`)
      .then(response => response.data)
  },
  createProject (_, { data }) {
    return axios.post('/projects/projects/', data)
      .then(response => response.data)
  },
  updateProject (_, { id, data }) {
    return axios.patch(`/projects/projects/${id}/`, data)
      .then(response => response.data)
  },
  listProjectsApps (_, { params } = {}) {
    return axios.get('/projects/projects/list_apps_by_user', { params })
      .then(response => response.data)
  },
  deleteProject (_, { id }) {
    return axios.delete(`/projects/projects/${id}/`)
      .then(response => response.data)
  }
}
