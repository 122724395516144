<template lang="pug">
.top-view-container
  ProjectDetailsHeader

  .flex-grow-1.flex-col
    v-tabs(v-model="currentTab").flex-grow-0
      v-tab(:to="{ path: `applications` }") Aplicaciones
      v-tab(:to="{ path: `processes` }") Procesos

    router-view.mt-5.flex-grow-1.pb-6(
      @changed="fetch"
    )
</template>

<script>
import { mapActions } from 'vuex'
import ProjectDetailsHeader from '@/components/project/details/ProjectDetailsHeader.vue'
import { mapFields } from 'vuex-map-fields'

export default {
  components: {
    ProjectDetailsHeader,
  },
  data() {
    return {
      loading: false,
      currentTab: 'details',
    }
  },

  computed: {
    ...mapFields('projectDetailsView', ['project']),
  },

  methods: {
    ...mapActions('projects', ['getProject']),

    async fetch() {
      this.loading = true
      try {
        const { id } = this.$route.params
        const data = await this.getProject({ id })
        this.project = data
        //
      } catch (error) {
        this.$toast.error('Error al cargar el proyecto')
        //
      } finally {
        this.loading = false
      }
    },
  },

  watch: {
    '$route.params.id': {
      handler(val) {
        if (!val) return
        this.fetch()
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.home-tile {
  border-radius: 10px !important;
}
</style>
