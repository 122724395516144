<template lang="pug">
div
  v-data-table.table(
    :headers="headers"
    :items="items"
    :options.sync="tableOptions"
    :loading="loading"
    :server-items-length="itemsLength"
    multi-sort
    hide-default-footer
  )
    //- Nombre
    template(#item.name="{ item }")
      .font-weight-bold(@click="openDetails(item.id)" style="cursor: pointer;") {{ item.name }}
    //- Procesos
    template(#item.processes="{ item }")
      v-menu(
        bottom offset-y
        transition="slide-y-transition"
        :close-on-content-click="false"
        :disabled="item.processes.length === 0"
      )
        template(v-slot:activator="{ on, attrs }")
          v-btn(text v-bind="attrs" v-on="on" color="#003399" :disabled="item.processes.length === 0").px-2
            span.mr-4 {{ item.processes.length }} Procesos
            v-icon.mr-n2 mdi-chevron-down
        v-list.pa-0
          v-list-item.process-item.pa-0(v-for="process in item.processes" :key="process.id")
            .process-box {{ process.name }}

    //- Acciones
    template(#item.actions="{ item }")
      v-btn.mr-1(text color="primary" @click="openDetails(item.id)").btn-action-details
        | Ver detalles
      v-btn.mr-1(v-if="$auth.userCan($auth.permissions.CAN_EDIT_PROJECT)" icon color="#8E9DE8" @click="openEdit(item.id)")
        v-icon mdi-pencil-outline
      v-btn(v-if="$auth.userCan($auth.permissions.CAN_DELETE_PROJECT)" icon color="#8E9DE8" @click="$refs.deleteProjectModal.open(item)")
        v-icon mdi-trash-can-outline
  DeleteProjectModal(ref="deleteProjectModal" @changed="fetch")
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import DeleteProjectModal from './DeleteProjectModal.vue'

export default {
  data() {
    return {
      loading: false,
      items: [],
    }
  },

  components: {
    DeleteProjectModal,
  },

  computed: {
    ...mapFields('projectListView', [
      'queryParams',
      'tableOptions',
      'itemsLength',
    ]),

    headers() {
      return [
        {
          text: 'Nombre',
          value: 'name',
          cellClass: 'font-weight-bold',
        },
        {
          text: 'Descripción',
          value: 'description',
        },
        {
          text: 'Procesos',
          value: 'processes',
          sortable: false,
        },
        {
          text: 'Acciones',
          value: 'actions',
          sortable: false,
        },
      ]
    },

    params() {
      return {
        ...this.queryParams,
        page: this.tableOptions.page,
      }
    },
  },

  methods: {
    ...mapActions('projects', ['listProjects']),

    // prettier-ignore
    async fetch() {
      this.loading = true
      try {
        const data = await this.listProjects({ params: this.params })
        this.items = data.results
        this.itemsLength = data.count

      } catch (error) {
        this.$toast.error('Error al cargar los datos.')

      } finally {
        this.loading = false
      }
    },

    openEdit(editProjectId) {
      const route = { query: { editProjectId } }
      this.$router.push(route).catch(() => {})
    },
    openDetails(id) {
      const route = { name: 'ProjectDetailsView', params: { id } }
      this.$router.push(route).catch(() => {})
    },
  },

  watch: {
    queryParams: {
      handler() {
        this.fetch()
      },
      deep: true,
    },
    tableOptions: {
      handler() {
        this.fetch()
      },
      deep: true,
    },
  },

  created() {
    this.fetch()
  },
}
</script>

<style lang="scss" scoped>
.table {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.table::v-deep {
  color: #003399;
  flex-grow: 1;

  th[role='columnheader'] {
    color: #8e9de8 !important;
    border: none !important;
    height: 36px;
    .v-icon {
      vertical-align: text-bottom;
    }
  }
}

.process-item {
  min-height: auto;
}

.process-box {
  background: #f9f9f9;
  border: 1px solid #f0f0f0;
  padding: 10px 14px;
  width: 100%;
}

.btn-action-details {
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-decoration: underline;
}
</style>
