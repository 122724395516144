import axios from '@/plugins/axios'
import moment from 'moment'
import fileDownload from 'js-file-download'

const getFilterParams = (filters) => {
  const { page } = filters
  const params = { page }

  if (filters.dateRange) {
    const dateRange = filters.dateRange.length === 2 ?
      [filters.dateRange[0], moment(filters.dateRange[1]).add(1, 'day').format('YYYY-MM-DD')] : [filters.dateRange[0], moment(filters.dateRange[0]).add(1, 'day').format('YYYY-MM-DD')]
    dateRange.sort((a, b) => moment(a).diff(moment(b)))
    params.created_at__range = dateRange.toString()
  }

  if (filters.taskType) {
    const taskParam = `is_${filters.taskType}`
    params[taskParam] = true
  }

  params.project = filters.project
  params.process = filters.process
  params.status = filters.status

  return params
}

export default {
  listTaskExecutions (_, { params } = {}) {
    params = getFilterParams(params)
    return axios.get('/projects/task-executions/', { params })
      .then(response => response.data)
  },
  getTaskExecution (_, { id }) {
    return axios.get(`/projects/task-executions/${id}/`)
      .then(response => response.data)
  },
  countStatus (_, { params } = {}) {
    params = getFilterParams(params)
    return axios.get('/projects/task-executions/status/', { params })
      .then(response => response.data)
  },
  getTaskExecutionLogs (_, { id }) {
    return axios.get(`/projects/task-executions/${id}/logs/`)
      .then(response => response.data)
  },
  getDuration (_, { params } = {}) {
    params = getFilterParams(params)
    return axios.get('/projects/task-executions/duration/', { params })
      .then(response => response.data)
  },
  exportJobs (_, { params } = {}) {
    params = getFilterParams(params)
    return axios.get('/projects/task-executions/export_jobs/', { params, responseType: 'blob' })
      .then((response) => {
        fileDownload(response.data, 'Trabajos.csv')
        return response
      })
  },
  getTaskExecutionsByDate(_, { params }) {
    params = getFilterParams(params)
    return axios.get('/projects/task-executions/count_by_date/', { params })
      .then(response => response)
  },
  stopTaskExecution (_, { id }) {
    return axios.post(`/projects/task-executions/${id}/stop/`)
      .then(response => response.data)
  }
}
