<template lang="pug">
.flex-col.gap-1
  .flex-row.justify-space-between
    h3 Proyecto
  .flex-row.justify-space-between.align-center
    .flex-row.gap-2
      v-avatar(color="#8E9DE8" rounded size="42px").home-tile
        v-icon mdi-home
      .flex-col
        h4 {{ project.name }}
        p {{ project.description }}

    HeaderStatsCards(:project="project")
</template>

<script>
import HeaderStatsCards from '@/components/project/details/HeaderStatsCards.vue'
import { mapFields } from 'vuex-map-fields';

export default {
  components: { HeaderStatsCards },
  computed: {
    ...mapFields('projectDetailsView', ['project']),
  },
}
</script>

<style lang="scss" scoped></style>
