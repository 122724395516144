import { getField, updateField } from 'vuex-map-fields'

const actions = {}

export default {
  namespaced: true,
  state: {
    queryParams: {
      search: null,
    },
    tableOptions: {},
    itemsLength: 0,
  },
  getters: { getField },
  mutations: { updateField },
  actions: actions,
}
