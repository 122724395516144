<template lang="pug">
.top-view-container
  ProjectListSearch.flex-grow-0
  .flex-row.flex-grow-1.gap-2
    .flex-col.flex-grow-1
      h3 Listado de proyectos
      ProjectListList(ref="list")
    ProjectListBanner(@createModal="newProjectModal = true")
  ProjectListPagination

  NewProjectModal(v-model="newProjectModal" @changed="refresh")
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import ProjectListBanner from '@/components/project/list/ProjectListBanner.vue'
import ProjectListSearch from '@/components/project/list/ProjectListSearch.vue'
import ProjectListList from '@/components/project/list/ProjectListList.vue'
import ProjectListPagination from '@/components/project/list/ProjectListPagination.vue'
import NewProjectModal from '@/components/project/list/NewProjectModal.vue'

export default {
  components: {
    ProjectListBanner,
    ProjectListSearch,
    ProjectListList,
    ProjectListPagination,
    NewProjectModal,
  },
  data() {
    return {
      newProjectModal: false,
    }
  },
  computed: {
    ...mapFields('projectListView', ['queryParams']),
  },
  methods: {
    refresh() {
      this.$refs.list.fetch()
    },
  },
  watch: {
    newProjectModal(val) {
      if (!val) {
        const route = { query: {} }
        this.$router.push(route).catch(() => {})
      }
    },

    '$route.query.editProjectId': {
      handler(val) {
        if (val) {
          this.newProjectModal = true
        }
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
h3 {
  color: #18287a;
}
</style>
