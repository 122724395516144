<template lang="pug">
v-app
  v-navigation-drawer(expand-on-hover app rail color="#18287A" nav width="236" :mini-variant.sync="miniVariant" mini-variant-width="120")
    .d-flex.flex-column.justify-center.px-6(style="height: 100%;")
      v-list(nav)
        v-list-item.mb-6(v-for="item in itemsByRole" :key="item.title" link dark :to="item.to" active-class="white--text accent")
          v-list-item-action.mr-0
            v-icon {{ item.icon }}
          v-list-item-content.ml-2
            v-list-item-title {{ item.title }}
    template(v-slot:prepend)
      div(style="height: 64px;")
        img.mx-auto.mt-9.ml-4(v-if="!miniVariant" src="@/assets/images/logo-auth.png" alt="Logo" width="194")
        img.mx-auto.mt-9.ml-9(v-else src="@/assets/images/logo-mini.svg" alt="Logo" width="48")
    template(v-slot:append)
      .text-center.mb-12
        v-btn(icon dark large @click="logout")
          v-icon mdi-logout
  v-main
    slot
</template>

<script>

export default {
  data () {
    return {
      miniVariant: true,
      items: [
        { title: 'Reportes', icon: 'mdi-ballot', to: '/overview/home' },
        { title: 'Proyectos', icon: 'mdi-ballot', to: '/projects', permission: this.$auth.permissions.CAN_SEE_PROJECT },
        { title: 'Fabrica de robots', icon: 'mdi-account', to: '/fabrica-bots', permission: this.$auth.permissions.CAN_SEE_BOT },
        { title: 'App', icon: 'mdi-chart-timeline-variant-shimmer', to: '/apps' },
      ],
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('auth/logout')
    }
  },
  computed: {
    itemsByRole () {
      return this.items.filter(item => {
        if (!item.permission) return true
        return this.$auth.userCan(item.permission)
      })
    }
  },
}
</script>
