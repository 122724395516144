import axios from '@/plugins/axios'

export default {
  listUsers (_, { params } = {}) {
    return axios.get('/projects/users/', { params })
      .then(response => response.data)
  },
  getUser (_, { id }) {
    return axios.get(`/projects/users/${id}/`)
      .then(response => response.data)
  },
}
