import axios from '@/plugins/axios'

export default {
  getBotsStats (_, { params } = {}) {
    return axios.get('/projects/bots/stats/', { params })
      .then(response => response)
  },
  getBotsDurationByDate (_) {
    return axios.get('/projects/bots/duration_by_date/')
      .then(response => response)
  },
  getCountErrors(_) {
    return axios.get('/projects/bots/count_errors/')
      .then(response => response)
  }
}
