export const vuetifyToastOptions = {
  queueable: true,
  classes: ['vuetify-toast'],
  shorts: {
    success: {
      color: '#CAFFD6',
      icon: 'mdi-check-circle',
      iconColor: '#34D39D',
    },
    warning: {
      color: '#FFFACA',
      icon: 'mdi-alert',
      iconColor: '#FFC22F',
    },
    error: {
      color: '#FFCACA',
      icon: 'mdi-alert-circle',
      iconColor: '#F04D62',
    },
  },
}
