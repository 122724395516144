<template lang="pug">
v-text-field(
  v-model="queryParams.search"
  placeholder="Buscar"
  prepend-inner-icon="mdi-magnify"
  outlined dense hide-details
)
</template>

<script>
import { mapFields } from 'vuex-map-fields';

export default {
  computed: {
    ...mapFields('projectListView', ['queryParams']),
  },
}
</script>

<style lang="scss" scoped>

</style>
