<template lang="pug">
v-form(ref="form" lazy-validation)
  v-text-field(dark background-color="accent" filled label="Nombre" v-model="form.name" :rules="v.name" :error-messages="errors.name || []")
  h3.white--text.mb-2 Parametros
  v-row(v-for="(param, index) in form.params" :key="index" dense)
    v-col(cols="4")
      v-text-field(hide-details dark background-color="accent" filled label="Nombre" v-model="param.field" :rules="v.field")
    v-col(cols="7")
      v-text-field(hide-details dark background-color="accent" filled label="Descripción" v-model="param.description" :rules="v.description")
    v-col(cols="1")
      v-btn.mb-4.mt-2(plain color="error" @click="form.params.splice(index, 1)" depressed large text icon)
        v-icon mdi-close-circle-outline
  v-btn.mb-4.mt-2(plain color="tertiary" @click="form.params.push({ field: null, description: null })" depressed large text) + Agregar parámetro
  v-file-input(
    dark v-model="newScript" background-color="accent" @change="changeFile($event)" accept=".py, .zip"
    filled :label="scriptFileName || 'Script'" :rules="v.script" prepend-inner-icon="mdi-file-document-outline" prepend-icon=""
    persistent-hint hint="Puede subir un archivo .py o un zip que contenga un main.py"
  )
    template(#append-outer)
      v-btn.mt-n1(v-if="downloadableScript" icon color="tertiary" @click.stop="downloadScript")
        v-icon mdi-download
  IconPicker(background-color="accent" filled label="Avatar (opcional)" v-model="form.avatar")
  .accent.pa-3.mb-4
    h3.white--text Estado
    v-radio-group(v-model="form.state" row :rules="v.state")
      v-radio(label="Borrador" value="Borrador" style="color: #003399;" dark)
      v-radio(label="Productivo" value="Productivo" style="color: #003399;" dark)
  v-textarea(filled dark label="Descripción" v-model="form.description" :rules="v.description" )
  v-btn(color="tertiary" @click="onSubmit" block depressed large) Guardar
</template>

<script>
import IconPicker from '../../common/IconPicker.vue'

export default {
  components: {
    IconPicker
  },
  props: {
    defaultValues: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      form: {
        name: this.defaultValues.name || '',
        description: this.defaultValues.description || '',
        avatar: this.defaultValues.avatar || '',
        state: this.defaultValues.state || 'Borrador',
        params: this.defaultValues.params || [
          { field: null, description: null }
        ],
        script: this.defaultValues.script || null,
        bot_file: null,
      },
      newScript: null
    }
  },
  methods: {
    onSubmit () {
      if (this.$refs.form.validate()) {
        const data = { ...this.form }
        data.params = data.params.filter((e) => e.field)
        data.params = data.params.map((e) => {
          if (e.field) {
            e.field = e.field.trim()
            e.description = e.description || ''
          }
          return e
        })
        data.params_str = JSON.stringify(data.params)
        delete data.params
        this.$emit('submit', data)
      }
    },
    changeParams (value) {
      this.form.params = value.map((e) => {
        if (typeof e === 'string') return { field: e }
        return e
      })
    },
    changeFile (file) {
      this.form.bot_file = file
    },
    downloadScript() {
      if (!this.downloadableScript) return
      window.open(this.downloadableScript, '_blank')
    },
  },
  computed: {
    v () {
      return {
        name: [
          v => !!v || 'El nombre es requerido'
        ],
        description: [
        ],
        state: [
          v => !!v || 'El estado es requerido'
        ],
        params: [
        ],
        script: [
          v => !!v || !!this.form.script || !!this.downloadableScript || 'El script es requerido'
        ]
      }
    },

    downloadableScript () {
      return this.defaultValues.bot_file
    },

    scriptFileName() {
      if (!this.downloadableScript) return
      const url = new URL(this.downloadableScript)
      return url.pathname.split('/').pop()
    }
  }
}
</script>
