<template lang="pug">
v-app
  v-main.h-100
    v-row.ma-0(style="height: 100%")
      v-col.h-100(style="background: #075EE7" cols="12" sm="6" md="4" lg="3")
        slot
      v-col.h-100(style="background: #003399" cols="0" sm="6" md="8" lg="9")
        v-img.mx-auto.mt-2(src="@/assets/images/auth-background.svg" width="60%")
</template>

<script>
export default {
  name: 'AuthLayout'
}
</script>
